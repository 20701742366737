@import '../../style/themes/index';
@import '../../style/mixins/index';

@pageheader-prefix-cls: ~'@{ant-prefix}-page-header';

.@{pageheader-prefix-cls} {
  .reset-component();
  position: relative;
  padding: @page-header-padding-vertical @page-header-padding;
  background-color: @component-background;

  &-ghost {
    background-color: @page-header-ghost-bg;
  }

  &.has-breadcrumb {
    padding-top: @page-header-padding-breadcrumb;
  }

  &.has-footer {
    padding-bottom: 0;
  }

  &-back {
    margin-right: @margin-md;
    font-size: 16px;
    line-height: 1;

    &-button {
      .operation-unit();
      color: @page-header-back-color;
      cursor: pointer;
    }
  }

  .@{ant-prefix}-divider-vertical {
    height: 14px;
    margin: 0 @margin-sm;
    vertical-align: middle;
  }

  .@{ant-prefix}-breadcrumb + &-heading {
    margin-top: @margin-xs;
  }

  .text-overflow-ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-heading {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      margin: (@margin-xs / 2) 0;
      overflow: hidden;
    }

    &-title {
      margin-right: @margin-sm;
      margin-bottom: 0;
      color: @heading-color;
      font-weight: 600;
      font-size: @page-header-heading-title;
      line-height: 32px;
      .text-overflow-ellipsis();
    }

    .@{ant-prefix}-avatar {
      margin-right: @margin-sm;
    }

    &-sub-title {
      margin-right: @margin-sm;
      color: @text-color-secondary;
      font-size: @page-header-heading-sub-title;
      line-height: @line-height-base;
      .text-overflow-ellipsis();
    }

    &-extra {
      margin: (@margin-xs / 2) 0;
      white-space: nowrap;

      > * {
        margin-left: @margin-sm;
        white-space: unset;
      }
      > *:first-child {
        margin-left: 0;
      }
    }
  }

  &-content {
    padding-top: @page-header-content-padding-vertical;
  }

  &-footer {
    margin-top: @margin-md;
    .@{ant-prefix}-tabs {
      > .@{ant-prefix}-tabs-nav {
        margin: 0;
        &::before {
          border: none;
        }
      }

      .@{ant-prefix}-tabs-tab {
        padding-top: @padding-xs;
        padding-bottom: @padding-xs;
        font-size: @page-header-tabs-tab-font-size;
      }
    }
  }

  &-compact &-heading {
    flex-wrap: wrap;
  }
}

@import './rtl';
