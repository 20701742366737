@import '../../style/themes/index';
@import '../../style/mixins/index';

@dialog-prefix-cls: ~'@{ant-prefix}-modal';
@confirm-prefix-cls: ~'@{ant-prefix}-modal-confirm';
@dialog-wrap-rtl-cls: ~'@{dialog-prefix-cls}-wrap-rtl';

.@{dialog-prefix-cls} {
  &-wrap {
    &-rtl {
      direction: rtl;
    }
  }

  &-close {
    .@{dialog-wrap-rtl-cls} & {
      right: initial;
      left: 0;
    }
  }

  &-footer {
    .@{dialog-wrap-rtl-cls} & {
      text-align: left;
    }
    .@{ant-prefix}-btn + .@{ant-prefix}-btn {
      .@{dialog-wrap-rtl-cls} & {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }

  &-confirm {
    &-body {
      .@{dialog-wrap-rtl-cls} & {
        direction: rtl;
      }
      > .@{iconfont-css-prefix} {
        .@{dialog-wrap-rtl-cls} & {
          float: right;
          margin-right: 0;
          margin-left: 16px;
        }
        + .@{confirm-prefix-cls}-title + .@{confirm-prefix-cls}-content {
          .@{dialog-wrap-rtl-cls} & {
            margin-right: 38px;
            margin-left: 0;
          }
        }
      }
    }
    &-btns {
      .@{dialog-wrap-rtl-cls} & {
        float: left;
      }
      .@{ant-prefix}-btn + .@{ant-prefix}-btn {
        .@{dialog-wrap-rtl-cls} & {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }
}

.@{dialog-prefix-cls}-centered {
  .@{dialog-prefix-cls} {
    .@{dialog-wrap-rtl-cls}& {
      text-align: right;
    }
  }
}
